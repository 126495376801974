
export default {
  name: 'PeopleThumb',

  props: {
    name: { type: String, default: null },
    job: { type: String, default: null },
    video: { type: String, default: null },
    poster: { type: String, default: null },
    href: { type: String, default: null },
    rotate: { type: Number, default: 0 },
  },

  data() {
    return {
      playing: false,
    }
  },

  watch: {
    playing(value) {
      if (!this.video) return
      value ? this.$refs.video.play() : this.$refs.video.pause()
    },
  },
}
