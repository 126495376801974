
export default {
  data() {
    return {
      pageCurrent: {},
    }
  },

  computed: {
    pageCount() {
      return 1
    },
  },

  mounted() {
    this.pageCurrent = 1
  },

  methods: {
    seeMore() {
      ++this.pageCurrent
    },
  },
}
