
import { fetchHome } from '@/assets/scripts/api'
import { bestPicFormat, randomizeArray } from '@/assets/scripts/utils'
import PageData from '@/mixins/PageData'
import SeeMore from '@/mixins/SeeMore'

const ITEM_PAGE_COUNT = 8

export default {
  name: 'IndexPage',
  mixins: [PageData, SeeMore],

  async asyncData({ payload }) {
    if (payload) {
      return { apiData: payload }
    }
    return { apiData: await fetchHome() }
  },

  data() {
    return {
      items: [],
      firstPeople: () => ({}),
    }
  },

  computed: {
    pageCount() {
      const peopleCount = this.apiData.peopleList.length - 1 // -1 because first people already displayed
      const peopleCountPages = peopleCount + Math.floor(peopleCount / 3) // after every 3 items there is a quote
      const quotesCount = this.apiData.quotes.length
      const quotesCountPages = quotesCount * 4 // for 1 quote there is 3 people
      return Math.max(
        Math.ceil(peopleCountPages / ITEM_PAGE_COUNT),
        Math.ceil(quotesCountPages / ITEM_PAGE_COUNT)
      )
    },
  },

  watch: {
    pageCurrent(value) {
      const maxLength = value * ITEM_PAGE_COUNT

      if (maxLength <= this.items.length) return

      let length = this.items.length
      let indexPeopleList =
        Math.max(this.items.filter((i) => i.type === 'people').length) + 1 // +1 because first index already displayed
      let indexQuotes = this.items.filter((i) => i.type === 'quote').length

      while (length < maxLength) {
        ;(length + 1) % 4 === 0
          ? this.items.push({
              type: 'quote',
              data: this.apiData.quotes[indexQuotes++],
            })
          : this.items.push({
              type: 'people',
              data: this.apiData.peopleList[indexPeopleList++],
            })
        ++length
      }
    },
  },

  created() {
    this.bestPicFormat = bestPicFormat
  },

  beforeMount() {
    this.$set(
      this.apiData,
      'peopleList',
      randomizeArray(this.apiData.peopleList)
    )
    this.$set(this.apiData, 'quotes', randomizeArray(this.apiData.quotes))
    this.$set(this.$data, 'firstPeople', this.apiData.peopleList[0])
  },
}
