import { render, staticRenderFns } from "./index.vue?vue&type=template&id=78032a9a&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=78032a9a&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78032a9a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PageTitle: require('/opt/build/repo/components/PageTitle.vue').default,HeaderMedia: require('/opt/build/repo/components/HeaderMedia.vue').default,TitleColorDuo: require('/opt/build/repo/components/TitleColorDuo.vue').default,ButtonBlob: require('/opt/build/repo/components/ButtonComponents/ButtonBlob.vue').default,PeopleThumb: require('/opt/build/repo/components/PeopleThumb.vue').default,ButtonBlobMore: require('/opt/build/repo/components/ButtonComponents/ButtonBlobMore.vue').default})
